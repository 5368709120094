import React, { useEffect, useState  } from 'react';
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Map from "components/Map";
import Suggest from 'components/form/Suggest';


const Rider = ({ location }) => {
  const [marker, setMarker] = useState();
  const [flyTo, setFlyTo] = useState();
  const [address, setAddress] = useState('');
  const [isFocused, setIsFocused] = useState();

  const onMapMarker = async e => {
    setMarker(e)
    console.log('onMapMarker',e);
  }

  const onAddress = async a => {
    setAddress(a)
    setMarker(null)
    setFlyTo(a.c)
    console.log('center address',a);
  }

  const suggestBlur = () => {
    console.log('suggestBlur');
    setIsFocused(false)
  }

  const suggestFocus = () => {
    console.log('suggestFocus');
    setIsFocused(true)
  }



  return (
    <Layout className={`layout-fixed ${isFocused?'isFocused':''}`}>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      <section>
        <div  className="cont">
          <h4>مكان الصعود</h4>
        </div>
      </section>
      <section className="body-fixed">
        <Map onMarker={setMarker} marker={marker} flyTo={flyTo}/>
        <div  className="cont">
          <Suggest
            address={address}
            onAddress={onAddress} placeholder={'بحث اسم المنطقة او البلدة...'}
            onFocus={suggestFocus}
            onBlur={suggestBlur}
           />
        </div>
      </section>
      <section>
        <div  className="cont">
          <Link to="../2" role="button" className="secondary wide">المتابعة 1/6</Link>
        </div>
      </section>
    </Layout>
  );
}

export default Rider
